import { PropsWithChildren } from "react";
import { useCurrentUser } from "shared/data";
import { AuthContextValue, useAuthContext } from "./AuthContext";
import { LoadingStatus } from "shared/types";
import PageLoader from "./PageLoader";
import { CONTINUE_URL_QUERY_PARAM } from "config";
import { continueUrlCheck } from "modules/home/pages/Login/Login.utils";
import { getQueryParamNative } from "shared/hooks/use-query-params";

interface AuthLoaderProps {
    user: AuthContextValue;
}

let firstLoad: boolean = true

export const useAuthLoader = () => {
    const { loading: cloudUserLoading } = useCurrentUser()
    const { loading: loadingStatus } = useAuthContext()
    const firebaseUserLoading = [LoadingStatus.Idle, LoadingStatus.Pending].includes(loadingStatus);

    const loading = cloudUserLoading || firebaseUserLoading
    if (!loading && firstLoad)
        firstLoad = false

    return {
        loading,
        firstLoad
    }
}

const AuthLoader = ({
    children
}: PropsWithChildren<AuthLoaderProps>) => {
    const { loading, firstLoad } = useAuthLoader()

    const continueUrl = getQueryParamNative(CONTINUE_URL_QUERY_PARAM)
    if (continueUrl) {
        const newPath = continueUrlCheck(continueUrl)
        if (newPath) {
            window.location.replace(newPath)
            return null
        }
    }

    return (
        <>
            {!firstLoad || !loading ? <>{children}</> : <PageLoader progressColor="secondary" fullHeight={true} />}
        </>
    )
}
export default AuthLoader
