import { Grid } from '@mui/material'
import { ReactNode } from 'react'
import { ActionsContainer, Container, Subtitle, Title, Wrapper } from './DetailsHeaderWithActions.styles'

interface HeaderProps {
    title: string
    children: ReactNode
    subtitle?: string
    hint?: string | null
}

const DetailsHeaderWithActions = ({ title, children, subtitle = '', hint = '' }: HeaderProps) => (
    <Container item container justifyContent="space-between">
        <Wrapper item container alignItems="flex-start" direction="column">
            <Grid item>
                <Title variant="h4">{title}</Title>
            </Grid>
            <Grid item>
                <Subtitle variant="h5">
                    <b>{subtitle}</b>
                </Subtitle>
            </Grid>
            {hint && (
                <Grid item>
                    <Subtitle variant="h6">{hint}</Subtitle>
                </Grid>
            )}
        </Wrapper>
        <ActionsContainer>
            {children}
        </ActionsContainer>
    </Container>
)

export default DetailsHeaderWithActions