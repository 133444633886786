import { MobileDatePicker, MobileDateTimePicker } from '@mui/lab'
import { TextField } from '@mui/material'
import { format, startOfDay } from 'date-fns'
import { forwardRef, Ref } from 'react'
import { Noop } from 'react-hook-form'
import { round, roundDown, roundUp } from 'shared/utils'
const DATETIME_FORMAT = 'dd.MM.yyyy HH:mm'
const DATETIME_INPUT_MASK = '__.__.____ __:__'
const timezone = format(new Date(), 'O')
const DATE_FORMAT = 'dd.MM.yyyy'
const DATE_INPUT_MASK = '__.__.____'

export enum RoundMode {
  Up,
  Down,
  Nearest,
  Daily
}

const DatePicker = forwardRef(
  (
    {
      onChange,
      onClose,
      value,
      onBlur,
      label,
      errorMessage,
      revalidateFunc,
      showTime = false,
      disabled = false,
      step,
      minDate,
      maxDate,
      useDateFormat
    }: {
      onChange: (val: Date | null) => any
      onClose?: () => void
      value: Date | null
      onBlur: Noop
      label?: string
      revalidateFunc: () => Promise<any>
      errorMessage?: string
      showTime?: boolean
      disabled?: boolean
      step?: {
        minutesStep: number,
        roundMode: RoundMode
      }
      minDate?: Date | null | undefined,
      maxDate?: Date | null | undefined,
      useDateFormat?: boolean
    },
    ref: Ref<HTMLInputElement>
  ) =>
    showTime ? (
      <MobileDateTimePicker
        onClose={() => {
          if (onClose) onClose()
        }}
        onChange={async date => {
          if (step) {
            if (date) {
              const stepValue = step.minutesStep * 60 * 1000;
              if (date.getTime() % stepValue !== 0) {
                switch (step.roundMode) {
                  case RoundMode.Down:
                    onChange(roundDown(stepValue, date))
                    break;
                  case RoundMode.Up:
                    onChange(roundUp(stepValue, date))
                    break;
                  case RoundMode.Nearest:
                    onChange(round(stepValue, date))
                    break;
                }
              } else
                onChange(date)
            } else
              onChange(null)
          }
          else
            onChange(date ? date : null)

          await revalidateFunc()
        }}
        minutesStep={step?.minutesStep}
        value={value}
        inputRef={ref}
        label={label ? `${label} (${timezone})` : undefined}
        inputFormat={DATETIME_FORMAT}
        mask={DATETIME_INPUT_MASK}
        disabled={disabled}
        minDate={minDate}
        maxDate={maxDate}
        renderInput={props => {
          return (
            <TextField
              onBlur={onBlur}
              autoComplete="off"
              fullWidth
              {...props}
              error={!!errorMessage}
              helperText={errorMessage}
            />
          )
        }}
      />
    ) : (
      <MobileDatePicker
        onClose={() => {
          if (onClose) onClose()
        }}
        onChange={async date => {
          onChange(date ? startOfDay(date) : null)
          await revalidateFunc()
        }}
        value={value}
        inputRef={ref}
        label={label ? `${label} (${timezone})` : undefined}
        inputFormat={useDateFormat ? DATE_FORMAT : DATETIME_FORMAT}
        mask={useDateFormat ? DATE_INPUT_MASK : DATETIME_INPUT_MASK}
        disabled={disabled}
        minDate={minDate}
        maxDate={maxDate}
        renderInput={props => {
          return (
            <TextField
              onBlur={onBlur}
              autoComplete="off"
              fullWidth
              {...props}
              error={!!errorMessage}
              helperText={errorMessage}
            />
          )
        }}
      />
    )
)

export default DatePicker
