import { Grid, Typography, styled } from '@mui/material'

export const Wrapper = styled(Grid)(({ theme }) => ({
  width: 'auto',
}))

export const Title = styled(Typography)({
  fontSize: '2.25rem',
  lineHeight: '2.625rem',
})

export const Subtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[600],
}))

export const ActionsContainer = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  gap: theme.spacing(1),
  display: 'flex',
  justifyContent: 'flex-end',
  marginBlock: 'auto',
}))

export const Container = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(5),
  width: 'auto',
}))
