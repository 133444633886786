import {
  isBefore,
  isAfter,
  isSameDay,
  addMinutes,
  startOfDay,
  addDays,
  isEqual,
  parseISO,
  format,
} from 'date-fns'
import {
  DateTimeFormatter,
  LocalDateTime,
  nativeJs,
  ZoneId,
} from '@js-joda/core'
import '@js-joda/timezone'
import { RoundMode } from 'shared/components/DatePicker'

const DEFAULT_PATTERN = 'd.MM.yyyy HH:mm' //for js-joda
const DEFAULT_DATE_PATTERN = 'd.MM.yyyy'

const DAY_OF_WEEK_PATTERN = 'E' //for date-fns

export const isDateInRange = (date: Date, fromDate: Date, toDate: Date) => {
  const isDateBefore = isBefore(date, toDate)
  const isDateAfter = isAfter(date, fromDate)

  return (
    (isDateAfter && isDateBefore) ||
    (isSameDay(date, fromDate) && isSameDay(date, toDate))
  )
}

export const generateMinuteIntervals = (
  options: {
    min?: Date
    max?: Date
    interval?: number
  } = {}
): Date[] => {
  const {
    min = startOfDay(new Date()),
    max = startOfDay(addDays(new Date(), 1)),
    interval = 15,
  } = options

  const timeIntervals: Date[] = [min]
  let timeInterval = addMinutes(min, interval)
  while (isEqual(timeInterval, max) || isBefore(timeInterval, max)) {
    timeIntervals.push(timeInterval)
    timeInterval = addMinutes(timeInterval, interval)
  }
  return timeIntervals
}

export const dateToLocalString = (date: Date) => {
  return LocalDateTime.from(nativeJs(date)).toString()
}

export const convertToTimezoneDate = (date: string, timezone: string) => {
  const localDate = LocalDateTime.parse(date)
  const timeZoneId = ZoneId.of(timezone)
  const zonedDate = localDate.atZone(timeZoneId)
  return zonedDate.toLocalDateTime()
}

export const convertToTimezoneDateString = (
  date: string,
  timezone: string,
  config?: {
    onlyDate?: boolean
    hideOffset?: boolean
    dayOffset?: number
  }
) => {
  const localDate = LocalDateTime.parse(date)
  const timeZoneId = ZoneId.of(timezone)
  let zonedDate = localDate.atZone(timeZoneId)
  if (config?.dayOffset) zonedDate = zonedDate.plusDays(config.dayOffset)
  const formatedDate = zonedDate.format(
    DateTimeFormatter.ofPattern(
      config?.onlyDate ? DEFAULT_DATE_PATTERN : DEFAULT_PATTERN
    )
  )
  let parsedDate = parseISO(date)
  if (config?.dayOffset) parsedDate = addDays(parsedDate, config?.dayOffset)
  const dayOfWeek = format(parsedDate, DAY_OF_WEEK_PATTERN)
  const timeOffset = zonedDate.format(DateTimeFormatter.ofPattern('x'))
  const displayDate = config?.hideOffset
    ? `${dayOfWeek} ${formatedDate}`
    : `${dayOfWeek} ${formatedDate} (GMT${timeOffset})`
  return displayDate
}

export const stringToLocalDate = (date: string) => {
  return new Date(date)
}

export const dateToTimezoneDateString = (date: Date, timezone: string) => {
  const localDate = LocalDateTime.from(nativeJs(date))
  const timeZoneId = ZoneId.of(timezone)
  const zonedDate = localDate.atZone(timeZoneId)
  const formatedDate = zonedDate.format(
    DateTimeFormatter.ofPattern(DEFAULT_PATTERN)
  )
  const dayOfWeek = format(date, DAY_OF_WEEK_PATTERN)
  const timeOffset = zonedDate.format(DateTimeFormatter.ofPattern('x'))
  const displayDate = `${dayOfWeek} ${formatedDate} (GMT${timeOffset})`
  return displayDate
}

export const dateToFormattedString = (
  date: Date,
  pattern: string = DEFAULT_PATTERN
) => {
  const localDate = LocalDateTime.from(nativeJs(date))
  const formatedDate = localDate.format(DateTimeFormatter.ofPattern(pattern))
  return formatedDate
}

export const convertDateToISOString = (date: Date) => {
  date.setSeconds(0, 0)
  return date.toISOString()
}

export const roundUp = (intervalMilliseconds: number, datetime?: Date) => {
  datetime = datetime || new Date()
  var modTicks = datetime.getTime() % intervalMilliseconds
  var delta = modTicks === 0 ? 0 : datetime.getTime() - modTicks
  delta += intervalMilliseconds
  return new Date(delta)
}

export const roundDown = (intervalMilliseconds: number, datetime?: Date) => {
  datetime = datetime || new Date()
  var modTicks = datetime.getTime() % intervalMilliseconds
  var delta = modTicks === 0 ? 0 : datetime.getTime() - modTicks
  return new Date(delta)
}

export const round = (intervalMilliseconds: number, datetime?: Date) => {
  datetime = datetime || new Date()
  var modTicks = datetime.getTime() % intervalMilliseconds
  var delta = modTicks === 0 ? 0 : datetime.getTime() - modTicks
  var shouldRoundUp = modTicks > intervalMilliseconds / 2
  delta += shouldRoundUp ? intervalMilliseconds : 0
  return new Date(delta)
}

export const roundToDate = (date: Date): Date =>
  new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0)

export const onChangeWithStep = (
  date: Date | null,
  step: { minutesStep: number; roundMode: RoundMode },
  onChange: (date: Date | null) => void
): Date | null => {
  if (date) {
    if (step.roundMode === RoundMode.Daily) {
      const changed = roundToDate(date)
      onChange(roundToDate(changed))
      return changed
    }
    const stepValue = step.minutesStep * 60 * 1000
    if (date.getTime() % stepValue !== 0) {
      switch (step.roundMode) {
        case RoundMode.Down: {
          const changed = roundDown(stepValue, date)
          onChange(changed)
          return changed
        }
        case RoundMode.Up: {
          const changed = roundUp(stepValue, date)
          onChange(changed)
          return changed
        }
        case RoundMode.Nearest: {
          const changed = round(stepValue, date)
          onChange(changed)
          return changed
        }
      }
    } else {
      onChange(date)
      return date
    }
  } else onChange(null)
  return null
}

export const convertLocalDateTimeForFilename = (datetime: Date) => {
  const year = datetime.getFullYear()
  const month = String(datetime.getMonth() + 1).padStart(2, '0')
  const day = String(datetime.getDate()).padStart(2, '0')
  const hours = String(datetime.getHours()).padStart(2, '0')
  const minutes = String(datetime.getMinutes()).padStart(2, '0')
  const seconds = String(datetime.getSeconds()).padStart(2, '0')
  return `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`
}
