import { useState, useEffect } from 'react'
import { useCurrentUser } from 'shared/data'
import { env, paths } from 'config'
import { getURLObject } from '../../utils'
import { useAuthLoader } from 'shared/components/AuthLoader'

export const tutorialShownCheck = () => {
  const tutorialShown = localStorage.getItem('tutorial_shown')
  if (tutorialShown === 'true') return true
  localStorage.setItem('tutorial_shown', 'true')
  return false
}

export const useRedirectRoute = () => {
  const { data, loading } = useCurrentUser()
  const { loading: globalLoading } = useAuthLoader()

  const [availableRoutes, setAvailableRoutes] = useState<string | null>(null)

  useEffect(() => {
    let route = null

    if (data && !loading && !globalLoading)
      route = data?.viewer.isAdmin ? paths.home : paths.parkingSpots

    setAvailableRoutes(route)
  }, [data, data?.viewer.isAdmin, loading, globalLoading])

  return availableRoutes
}

export const continueUrlCheck = (continueUrl: string) => {
  const urlObject = getURLObject(continueUrl)
  let continueUrlOrigin = ''
  let continueUrlSearch = ''
  if (urlObject) {
    continueUrlOrigin = urlObject.origin
    continueUrlSearch = urlObject.search
  }

  const { location } = window
  if (
    !continueUrl ||
    !env.continueUrlRedirect ||
    env.continueUrlRedirect === 'false' ||
    continueUrlOrigin === env.appUrl
  )
    return null
  let windowUrlSearch = ''
  const windowUrlObject = getURLObject(location.href)
  if (windowUrlObject) {
    windowUrlSearch = windowUrlObject.searchParams.toString()
  }
  const addQuestionCharacter =
    windowUrlSearch.length > 0 && continueUrlSearch.length === 0
  const addAndCharacter =
    windowUrlSearch.length > 0 && continueUrlSearch.length > 0
  return `${continueUrl}${
    addQuestionCharacter ? '?' : addAndCharacter ? '&' : ''
  }${windowUrlSearch}`
}
