import { useQuery } from '@apollo/client'
import { GET_CURRENT_USER, GET_TIMEZONES } from './queries'
import { GetCurrentUserQuery, getTimezonesQuery } from './types'

export const useCurrentUser = () =>
  useQuery<GetCurrentUserQuery>(GET_CURRENT_USER, {
    fetchPolicy: 'cache-first',
  })

export const useTimezones = () =>
  useQuery<getTimezonesQuery>(GET_TIMEZONES, {
    fetchPolicy: 'cache-first',
  })
