import { GridRowId } from '@mui/x-data-grid'
import { useState, useRef, useEffect } from 'react'
import { PageInfo } from 'shared/types'
import { getPageOnBySource, PaginationSource } from './use-pagination'
import { setLocalStorageValue } from 'shared/utils'

export const ROWS_PER_PAGE_OPTIONS = [5, 10, 20, 50]
const cacheKey = (source: PaginationSource) => `Pagination_OnPage_${source}`

export const useDataGridPagination = (
  loading: boolean,
  totalCount: number | undefined,
  pageInfo: PageInfo | undefined,
  source: PaginationSource
) => {
  const INITIAL_PAGE_SIZE = getPageOnBySource(source) ?? 10
  const [pageSize, setPageSize] = useState(INITIAL_PAGE_SIZE)
  const mapPageToNextCursor = useRef<{ [page: number]: GridRowId }>({})
  const [page, setPage] = useState(0)
  const cursor = mapPageToNextCursor.current[page - 1]
    ? mapPageToNextCursor.current[page - 1].toString()
    : ''

  useEffect(() => {
    if (!loading && pageInfo?.endCursor) {
      mapPageToNextCursor.current[page] = pageInfo?.endCursor
    }
  }, [page, loading, pageInfo?.endCursor])

  const [rowCountState, setRowCountState] = useState(totalCount || 0)

  useEffect(() => {
    setRowCountState(prevRowCountState =>
      totalCount !== undefined ? totalCount : prevRowCountState
    )
  }, [totalCount, setRowCountState])

  const handlePageChange = (newPage: number) => {
    if (newPage === 0 || mapPageToNextCursor.current[newPage - 1]) {
      setPage(newPage)
    }
  }

  const _setPageSize = (size: number) => {
    setLocalStorageValue(cacheKey(source), size)
    setPageSize(size)
  }

  return {
    pageSize,
    setPageSize: _setPageSize,
    page,
    setPage,
    cursor,
    rowCountState,
    handlePageChange,
  }
}

export const useDataGridPagination2 = (
  totalCount: number | undefined,
  pageInfo: {
    endCursor: string | null
    startCursor: string | null
    hasNextPage: boolean
    hasPreviousPage: boolean
  },
  source: PaginationSource,
  initPage: number
) => {
  const INITIAL_PAGE_SIZE = getPageOnBySource(source) ?? 10
  const [pageSize, setPageSize] = useState(INITIAL_PAGE_SIZE)
  const [page, setPage] = useState(initPage)
  const [cursor, setCursor] = useState<{
    before?: string
    after?: string
  }>({
    ...(pageInfo.startCursor !== null && { after: pageInfo.startCursor }),
    ...(pageInfo.endCursor && { before: pageInfo.endCursor }),
  })

  const [rowCountState, setRowCountState] = useState(totalCount || 0)

  useEffect(() => {
    setRowCountState(prevRowCountState =>
      totalCount !== undefined ? totalCount : prevRowCountState
    )
  }, [totalCount, setRowCountState])

  const handlePageChange = (newPage: number) => {
    if (newPage === 0) {
      setPage(newPage)
      setCursor({ after: '' })
      return
    }
    if (newPage > page) {
      setPage(prev => prev + 1)
      setCursor({ after: pageInfo.endCursor ?? '' })
      return
    }
    if (newPage < page) {
      setPage(prev => prev - 1)
      setCursor({
        before: pageInfo.startCursor ?? '',
      })
    }
  }

  const _setPageSize = (size: number) => {
    setLocalStorageValue(cacheKey(source), size)
    setPageSize(size)
  }

  return {
    pageSize,
    setPageSize: _setPageSize,
    page,
    setPage,
    cursor,
    rowCountState,
    handlePageChange,
  }
}
